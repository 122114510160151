/**
 * We want to disable spellcheck for automated tests. We generate a lot of fake
 * data and often times that data was being munged by autocorrect.
 */
(function () {
   if (Cookie.read('comms-TEST_NAME')) {
      $$('textarea').set('spellcheck', false);
      $$('input').set('spellcheck', false);
   }
})();
