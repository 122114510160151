 

function getter(json) {
   return function(key) {
      if (!json.hasOwnProperty(key)) {
         var errorStr = 'Property ' + key + ' does not exist!';
         throw errorStr;
      }
      return json[key];
   };
}

const shared_constants = {
   BaseURI: getter({
      SITE_IMAGE_LOADING_SMALL: '/static/images/loading-small.gif',
      SITE_IMAGE_CART_LOADING: '/static/images/cart/loading-20100504.gif',
      SITE_IMAGE_BLANK: '/static/images/blank.gif',
      SITE_IMAGE_BLURBS_LOADING: '/static/images/guide/blurbs-loading.gif',
      SITE_IMAGE_AJAX_LOAD: '/static/images/sales/ajax_load.gif',
      SITE_IMAGE_MODAL_SPINNER: '/static/images/modal/spinner.gif',
      SITE_IMAGE_FLAG_32: function(countryCode) {
         // The output of this PHP is evaluated at asset build time and results
         // in: "/static/images/country_flags/COUNTRY_CODE.png", which is a
         // valid JS string literal. At runtime, we replace COUNTRY_CODE with
         // the passed-in country code (e.g., DE.png).
         var uri = "/static/images/country_flags/32/COUNTRY_CODE.png";
         return uri.replace('COUNTRY_CODE', countryCode);
      },
      SITE_IMAGE_FLAG_64: function(countryCode) {
         // See SITE_IMAGE_FLAG_32.
         var uri = "/static/images/country_flags/64/COUNTRY_CODE.png";
         return uri.replace('COUNTRY_CODE', countryCode);
      },
   }),

   Global: getter({
      EMBED_ASPECT_RATIO: parseFloat('0.73'),
      LARGE_EMBED_HEIGHT: parseInt('529', 10),
      REGULAR_EMBED_HEIGHT:  parseInt('357', 10)
   }),

   GuideURI: getter({
      TOPIC_NO_IMAGE_MINI: '/static/images/default_images/TopicNoImage_56x42.jpg',
      NO_IMAGE_MINI: '/static/images/guide/NoImage_56x42.jpg',
      GUIDE_NO_MAIN_IMAGE_MINI: '/static/images/default_images/GuideNoImage_56x42.jpg',
      GUIDE_IMAGE_REDIRECT_ORIGINAL: '/Guide/Image/meta/redirect/original/',
      GUIDE_IMAGE_METADATA: '/Guide/Image/meta/',
      PREVIEW_IMAGE: '/Guide/image/preview',

      TAG_RESET_PASSWORD: '/User/reset-password',
      TAG_USER_SETUP: '/User/user-setup',
      TAG_LOGOUT: '/Guide/logout',
      TAG_SSO_CLOSE_WINDOW: '/CLOSE_THIS_WINDOW',
      TAG_USER_DETAILS: '/Guide/User/index/details',
      TAG_LOGIN: '/Login',
      TAG_SITE_HOMEPAGE: '/',
      TAG_SEARCH: '/Search'
   })
}
window.shared_constants = shared_constants;
export default shared_constants;
