/**
 * Ensures a rejected promise is reported so they are seen, but don't stop the
 * promise chain so other handlers can be used downstream.
 *
 * Note: we have to re-throw so the returned promise is also rejected
 */
Promise.prototype.reportErrors = function () {
   return this.then(null, function (err) {
      setTimeout(function () {
         throw typeof err == 'string' || !err ? new Error(err) : err;
      });
      throw err;
   });
};

/**
 * Returns a standards compliant promise that resolves to the same thing
 * as the passed promise. This can be used to convert a somewhat non-standard
 * promise i.e. from jQuery.ajax() into one that uses our Promise prototype.
 */
Promise.from = function (promise) {
   return new Promise(function (resolve) {
      resolve(promise);
   });
};
