when(document.querySelectorAll('.resolution-switch'), containers => {
   containers.forEach(container => {
      container.addEvent('click', ev => {
         const button = ev.target;
         const buttonClasses = button.classList;

         // Clicks on the container or the current resolution should be ignored
         if (!buttonClasses.contains('resolution-button') || buttonClasses.contains('selected')) {
            return;
         }

         // Swap the selected class right away to prevent multiple clicks
         const selected = container.getElement('.selected');
         if (selected) {
            selected.classList.toggle('selected');
         }
         buttonClasses.toggle('selected');

         const video = container.getParent().getElement('video');
         const curTime = video.currentTime;
         const source = video.getElement('source[label=' + button.get('data-source') + ']');

         source.remove();
         video.prepend(source);
         video.load();
         video.currentTime = curTime;
         video.play();
      });
   });
});
