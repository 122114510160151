import trackInMatomo, { TrackEvent } from './Matomo/MatomoEvent';

export type GATrackEvent = {
   /**
    * Describes the type of events you want to track.
    * For example, Link Clicks, Videos, Outbound Links, and Form Events.
    */
   eventCategory: string;
   /**
    * The specific action that is taken.
    * For example, with a Video category, you might have a Play, Pause and Complete action.
    */
   eventAction: string;
   /**
    * Usually the title of the element that is being interacted with, to aid with analysis.
    * For example, it could be the name of a Video that was played or the specific
    * form that is being submitted.
    */
   eventName: string;
};

const trackInMatomoAndGA = (trackData: TrackEvent & GATrackEvent) => {
   trackInMatomo(trackData);

   // turning off GA on conversion to GA4 6/29/2023 - MG
   // @ts-ignore
   // const site = App.which;
   // if (window.ga && site) {
   //    window.ga(
   //       `${site}.send`,
   //       'event',
   //       trackData.eventCategory,
   //       trackData.eventAction,
   //       trackData.eventName
   //    );
   // }
};

const trackLinkInMatomoAndGA = (link: HTMLElement, trackData: TrackEvent & GATrackEvent) => {
   link.addEventListener('click', event => {
      event.preventDefault();
      trackInMatomoAndGA(trackData);
      const url = link.getAttribute('href');
      if (url) {
         setTimeout(() => (document.location = url), 500);
      }
   });
};

export default trackInMatomoAndGA;
export { trackLinkInMatomoAndGA };
