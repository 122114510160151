import strftime from 'strftime';

const SECOND = 1000,
   MINUTE = 60 * SECOND,
   HOUR = 60 * MINUTE,
   DAY = 24 * HOUR,
   WEEK = 7 * DAY,
   YEAR = DAY * 365,
   MONTH = YEAR / 12;

onDomReady(function () {
   // Refresh interval in milliseconds.
   let refreshInterval = MINUTE;
   (function update() {
      $$('.js-realtime-date').each(function (el) {
         try {
            const dateHtml = relativeDate(new Date(el.title));

            if (dateHtml) {
               el.set('html', dateHtml);
            }
         } catch (e) {
            // Do nothing. Since this is an HTML5 time element older browsers
            // may have trouble with this.
         }
      });
      setTimeout(update, refreshInterval);
   })();

   let absoluteDate = function (dateFormat, time) {
      let locale = App.locale;
      let localizedStrftime = strftime.localizeByIdentifier(locale);
      return localizedStrftime(dateFormat, time || new Date().getTime());
   };

   $$('.js-absolute-date').each(function (el) {
      try {
         el.set('html', absoluteDate(el.get('data-format'), new Date(el.title)));
      } catch (e) {
         // Do nothing. Since this is an HTML5 time element older browsers
         // may have trouble with this.
      }
   });
});

// Todo: Implement plural _js function
function relativeDate(time) {
   let delta = new Date().getTime() - time.getTime();
   let isFutureDate = delta < 0;
   let modifiedDelta;
   let message;

   // The delta is used to find the time bucket and get the unit name so we
   // always want it to be positive.
   delta = Math.abs(delta);

   // Note: this should be the same as the "just now" check
   // in Date::timeSince().
   if (delta < 15 * SECOND) {
      message = _js('just now');
   } else if (delta < MINUTE) {
      modifiedDelta = Math.floor(delta / SECOND);
      message = isFutureDate ? _js('%1 seconds from now') : _js('%1 seconds ago');
   } else if (delta < 2 * MINUTE) {
      message = isFutureDate ? _js('1 minute from now') : _js('1 minute ago');
   } else if (delta < HOUR) {
      modifiedDelta = Math.floor(delta / MINUTE);
      message = isFutureDate ? _js('%1 minutes from now') : _js('%1 minutes ago');
   } else {
      // If the delta is >= 1 hour then updating it every minute (the referesh interval at
      // the time of this writing) doesn't really make sense.
      return null;
   }

   return message.replace('%1', modifiedDelta);
}

export { relativeDate, MINUTE };
