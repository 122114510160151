import { trackLinkInMatomoAndGA } from './CombinedGAMatomo';

const trackableLinks = document.querySelectorAll(
   '[data-analytics-event-category]'
) as unknown as Array<HTMLElement>;

trackableLinks.forEach(link => {
   trackLinkInMatomoAndGA(link, {
      eventCategory: link.dataset.analyticsEventCategory,
      eventAction: 'click',
      eventName: link.dataset.analyticsEventName,
   });
});
