import Handlebars from 'handlebars';

(function () {
   let sizeWidths = App.imageSizeWidths;
   // Cause a mini is quite small and it isn't worth only showing a mini
   delete sizeWidths.mini;

   /**
    * Note: This is an analog to the ViewUtils.php function imgSrcSet()
    * Returns html of a sizes=.. and srcset=.. attribute for creating responsive
    * images.
    *
    * image: object with sizename:url key-value mappings for each size available
    *       The api returns this object for nearly every image.
    * viewSize: Best guess about the width this image will be displayed at.
    *            default is the width of the viewport "100vw", can also accept
    *            pixel sizes and media queries to give the browser a hint as to
    *            how big the image will be at different browser widths.
    * maxSize: size name of the maximum size that should be included. IF you know
    *           this image won't be shown bigger than 200px, you can prevent
    *           inclusion of large and huge sizes to save on html.
    */
   Handlebars.registerHelper('imgSrcSet', function (image, viewSize, maxSize) {
      let encode = Handlebars.escapeExpression;
      maxSize = maxSize || 'large';

      let sizesAttr = viewSize ? 'sizes="' + encode(viewSize) + '"' : 'sizes="100vw"',
         sizes = [];

      Object.keys(sizeWidths).forEach(function (sizeName) {
         if (sizeWidths[sizeName] <= sizeWidths[maxSize] && (!image || image[sizeName])) {
            sizes.push(encode(image[sizeName]) + ' ' + sizeWidths[sizeName] + 'w');
         }
      });

      if (!sizes.length) {
         return null;
      }

      /**
       * iOS 8 Safari only reads the first srcset URL if the unit is width (w)
       * based. Flip to default to largest image.
       */
      sizes.reverse();

      return new Handlebars.SafeString(sizesAttr + ' srcset="' + sizes.join(',') + '"');
   });

   /**
    * Borrowed from the idea of our ViewUtils::check().
    * Check that a value is truthy. If not, use a default value.
    */
   Handlebars.registerHelper('check', function (value, defaultValue, options) {
      // If the options variable is undefined, that means that the "options"
      // are stored in defaultValue as an Object, so we want to reset the
      // defaultValue so that it doesn't actually use the Object.
      if (typeof options === 'undefined') {
         defaultValue = '';
      }
      if (typeof value === 'undefined' && typeof options === 'undefined') {
         throw 'Error: value is undefined and no default value was given';
      }
      return value || defaultValue;
   });

   /**
    * Simple ternary operator for handlebars
    */
   Handlebars.registerHelper('ternary', function (test, yes, no) {
      return test ? yes : no;
   });

   /**
    * Handlebar wrapper for _js
    */
   Handlebars.registerHelper('_js', function () {
      return _js.apply(window, arguments);
   });
})();
