/**
 * Lazy load background images via lazysizes with `class="lazyload"` and
 * `data-bg="image_url.jpg"`.
 */
document.addEventListener('lazybeforeunveil', function (e) {
   let bg = e.target.getAttribute('data-bg');
   if (bg) {
      e.target.style.backgroundImage = 'url(' + bg + ')';
   }
});
