 

function getter(json) {
   return function(key) {
      if (!json.hasOwnProperty(key)) {
         var errorStr = 'Property ' + key + ' does not exist!';
         throw errorStr;
      }
      return json[key];
   };
}

window.hbsTemplates = getter({
   APPROVAL_REQUEST_HBS: Template.get("<div class=\"card card-approval-request request\">\n   <div class=\"card-component\">\n      <div class=\"left\">\n         <div class=\"release-title\">{{releaseTitle}}<\/div>\n         <div class=\"request-details\">\n            <div class=\"request-details-row\">\n               <div class=\"request-guide\">\n                  <span class=\"label\">{{_js 'For Guide:'}}<\/span\n                  ><a href=\"{{guideHref}}\">{{guideTitle}}<\/a>\n               <\/div>\n               <div class=\"request-release\">\n                  <span class=\"label\">{{_js 'At Release:'}}<\/span\n                  ><a href=\"{{releaseHref}}\">{{release.title}}<\/a>\n               <\/div>\n            <\/div>\n            <div class=\"request-details-row\">\n               <div class=\"request-process\">\n                  <span class=\"label\">{{_js 'With Process:'}}<\/span\n                  ><a href=\"{{processHref}}\">{{process.name}}<\/a>\n               <\/div>\n               <div class=\"request-init-date\">\n                  <span class=\"label\">{{_js 'Started on:'}}<\/span\n                  ><span>{{dateString}}<\/span>\n               <\/div>\n            <\/div>\n         <\/div>\n      <\/div>\n      <div class=\"right\">\n         <div class=\"request-status {{requestStatusClass}}\">{{requestStatus}}<\/div>\n         <div class=\"request-review\">\n            <a href=\"{{requestReviewHref}}\">\n               <div class=\"card card-approval-request card-hover\">\n                  <div class=\"card-component card-component-shaded-dark review\">\n                     {{_js 'Review'}}\n                  <\/div>\n               <\/div>\n            <\/a>\n         <\/div>\n         <div class=\"clearer\">\n         <\/div>\n      <\/div>\n   <\/div>\n<\/div>\n"),
   APPROVAL_REQUEST_V2_HBS: Template.get("<div class=\"card card-approval-request\" data-status=\"{{status}}\" data-id=\"{{approval_requestid}}\">\n   <div class=\"request-image\">\n      <a href=\"{{href}}\">\n         <img class=\"doc-thumbnail\" src=\"{{img_src}}\" alt=\"{{_js 'Document thumbnail image'}}\"\/>\n      <\/a>\n   <\/div>\n   <div class=\"request-metadata\">\n      <a href=\"{{href}}\">\n         <p class=\"doc-title request-metadata-text\">{{title}}<\/p>\n      <\/a>\n      <p class=\"highlight-text highlight-{{status_text_color}}\">{{status_text}}<\/p>\n      <p class=\"request-metadata-text request-release-text\">\n         {{_js 'Release'}}: <a href=\"{{release.href}}\">{{release.title}}<\/a>\n      <\/p>\n      <p class=\"request-metadata-text request-process-text\">\n         {{_js 'Process'}}:\n         {{#if isAdmin}}\n            <a href=\"{{process.href}}\">{{process.name}}<\/a>\n         {{else}}\n            {{process.name}}\n         {{\/if}}\n      <\/p>\n   <\/div>\n   <div class=\"request-action\">\n      <p class=\"request-action-text request-action-date-text\">{{_js 'Started: %1' init_date}}<\/p>\n      <a class=\"button {{ternary pending 'button-action-solid' ''}} request-action-button button-large\"\n       href=\"{{href}}\">\n         {{ternary pending review_text review_again_text}}\n      <\/a>\n   <\/div>\n<\/div>\n  \n"),
   AUTHOR_AUTOCOMPLETER_ITEM_HBS: Template.get("<li class=\"author-suggest-item\" id=\"author-{{userid}}\" data-userid=\"{{userid}}\"\n data-username=\"{{name}} {{uniqueName}}\" data-image=\"{{avatar}}\">\n   <div class=\"author-suggest-info\">\n      <img class=\"author-suggest-avatar\" src=\"{{avatar}}\">\n      <div class=\"author-suggest-name\">\n         {{name}}\n      <\/div>\n   <\/div>\n<\/li>\n"),
   BLURB_HBS: Template.get("<li class=\"blurb-item\" id=\"{{blurbId}}\">\n   {{#if showImage}}\n      <div class=\"blurb-image\">\n         <img src=\"{{blurbImage}}\" alt=\"{{title}} Image\">\n      <\/div>\n   {{\/if}}\n   <div class=\"blurb-text\">\n      <p class=\"blurb-title\">{{displayTitle}}<\/p>\n      <p class=\"blurb-summary hidden-mobile\">{{summary}}<\/p>\n   <\/div>\n<\/li>\n"),
   SUGGEST_GLOBAL_TOGGLE_HBS: Template.get("<div class=\"blurb-finder-wrap\" styles=\"z-index:{{zIndex}}\">\n   <ul class=\"{{choicesClass}}\"><\/ul>\n   <p class=\"no-matches js-no-matches\">\n            {{{_js 'No results for %1' '<span><\/span>'}}}\n   <\/p>\n   <p class=\"blurb-finder-global-toggle js-blurb-finder-global-toggle\">\n      <span class=\"when-not-global\">{{_js 'Search all guides'}}\n       <i class=\"fa fa-search-plus \" ><\/i><\/span>\n\n            <span class=\"when-global\">{{_js 'Search guides belonging to this %1' objectNameSingular}}\n       <i class=\"fa fa-search-minus \" ><\/i><\/span>\n   <\/p>\n<\/div>\n"),
   TAG_AUTOCOMPLETER_ITEM_HBS: Template.get("<li>{{title}}<\/li>\n"),
   USER_HBS: Template.get("<li class=\"blurb-item\" id=\"{{blurbId}}\">\n   {{#if showImage}}\n      <div class=\"blurb-image one-one\">\n         <img src=\"{{userImage}}\" alt=\"{{user.username}} Avatar\">\n      <\/div>\n   {{\/if}}\n   <div class=\"blurb-text\">\n      <p class=\"blurb-title\">{{user.username}}\n         {{#if showReputation}}<span class=\"reputation\" title=\"{{reputationTitle}}\">({{user.reputation}})<\/span>{{\/if}}\n      <\/p>\n      <p>Userid: {{user.userid}}<\/p>\n   <\/div>\n<\/li>\n"),
   TEAM_HBS: Template.get("<li class=\"blurb-item\" id=\"team-{{team.teamid}}\">\n   {{#if showImage}}\n      <div class=\"blurb-image one-one\">\n         <img src=\"{{imageUrl}}\" alt=\"{{team.name}} Avatar\">\n      <\/div>\n   {{\/if}}\n   <div class=\"blurb-text\">\n      <p class=\"blurb-title\">{{team.name}}<\/p>\n      <p>Teamid: {{team.teamid}}<\/p>\n   <\/div>\n<\/li>\n"),
   LOADING_INDICATOR_HBS: Template.get("<div class=\"loading-indicator-container\">\n   <div class=\"loading-indicator pending entering\">\n       \n<div class=\"message-container\">\n   {{#if pending}}\n      <span class=\"spinner\">\n         <span class=\"spinner-circle\"><\/span>\n         <span class=\"spinner-edge\"><\/span>\n      <\/span>\n   {{\/if}}\n   {{#if success}}\n      <span class=\"icon-animate pop\"><i  class=\"svg-icon\"   style=\"width: 24px; height: 24px;\"><svg xmlns=\"http:\/\/www.w3.org\/2000\/svg\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\">\n   <path d=\"M22,11.07q0,.46,0,.93a10,10,0,1,1-5.93-9.14\"\/>\n   <polyline points=\"23 3 12 14 9 11\"\/>\n<\/svg><\/i><\/span>\n   {{\/if}}\n   {{#if failure}}\n      <i  class=\"svg-icon\"   style=\"width: 24px; height: 24px;\"><svg viewBox=\"0 0 24 24\" fill=\"none\" stroke-linejoin=\"round\" stroke-width=\"2\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-miterlimit=\"10\">\n  <path d=\"M13.71,3.86,22.18,18a2,2,0,0,1-1.71,3H3.53a2,2,0,0,1-1.71-3L10.29,3.86A2,2,0,0,1,13.71,3.86Z\"\/>\n  <line x1=\"12\" y1=\"9\" x2=\"12\" y2=\"13\"\/>\n  <line x1=\"12\" y1=\"17\" x2=\"12\" y2=\"17\"\/>\n<\/svg><\/i>   {{\/if}}\n   <span class=\"message\">{{ message }}<\/span>\n<\/div>\n   <\/div>\n<\/div>\n"),
   LOADING_INDICATOR_MESSAGE_HBS: Template.get("<div class=\"message-container\">\n   {{#if pending}}\n      <span class=\"spinner\">\n         <span class=\"spinner-circle\"><\/span>\n         <span class=\"spinner-edge\"><\/span>\n      <\/span>\n   {{\/if}}\n   {{#if success}}\n      <span class=\"icon-animate pop\"><i  class=\"svg-icon\"   style=\"width: 24px; height: 24px;\"><svg xmlns=\"http:\/\/www.w3.org\/2000\/svg\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\">\n   <path d=\"M22,11.07q0,.46,0,.93a10,10,0,1,1-5.93-9.14\"\/>\n   <polyline points=\"23 3 12 14 9 11\"\/>\n<\/svg><\/i><\/span>\n   {{\/if}}\n   {{#if failure}}\n      <i  class=\"svg-icon\"   style=\"width: 24px; height: 24px;\"><svg viewBox=\"0 0 24 24\" fill=\"none\" stroke-linejoin=\"round\" stroke-width=\"2\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-miterlimit=\"10\">\n  <path d=\"M13.71,3.86,22.18,18a2,2,0,0,1-1.71,3H3.53a2,2,0,0,1-1.71-3L10.29,3.86A2,2,0,0,1,13.71,3.86Z\"\/>\n  <line x1=\"12\" y1=\"9\" x2=\"12\" y2=\"13\"\/>\n  <line x1=\"12\" y1=\"17\" x2=\"12\" y2=\"17\"\/>\n<\/svg><\/i>   {{\/if}}\n   <span class=\"message\">{{ message }}<\/span>\n<\/div>\n"),
   MEDIA_LIBRARY_UPLOADER_HBS: Template.get("<div id=\"file-drop\" class=\"mm-uploader\">\n   <i class=\"fa fa-file-pdf-o \" ><\/i>   <i class=\"fa fa-file-image-o \" ><\/i>   <i class=\"fa fa-file-video-o \" ><\/i>   <h3 class=\"add-media-text\">\n            {{{_js 'Drag & drop here %1or browse for files%2' '<br><a class=\"mediaButton qq-upload-button\" id=\"addMedia\">' '<\/a>'}}}\n   <\/h3>\n<\/div>\n"),
});
